import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomValidatorComponent } from './custom.validator.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [CustomValidatorComponent],
  exports: [CustomValidatorComponent],
  providers: [],
  imports: [CommonModule, FontAwesomeModule, InputModule],
})
export class CustomValidatorModule {}
